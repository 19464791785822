import { gql } from 'apollo-boost';

export const UNBLOCK_DANCER = gql`
  mutation DancerUnblock($businessId: Int!, $dancerId: Int!) {
    dancer_unblock(input: { businessId: $businessId, dancerId: $dancerId }) {
      dancer {
        id
        blocked_by {
          id
          name
        }
      }
    }
  }
`;
