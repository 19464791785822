import { gql } from 'apollo-boost';
import { SHIFT_APPLICATION_FRAGMENT } from '../fragments/shift-application-fragment';

export const UNDO_RATING_GQL = gql`
  mutation ShiftApplicationRateUndo($id: ID!) {
    shift_application_rate_undo(input: { id: $id }) {
      shift_application {
        id
        rating_sub_one
        rating_sub_two
        rating_sub_three
      }
    }
  }
`;

export const UNDO_RATING_BATCH_GQL = gql`
  ${SHIFT_APPLICATION_FRAGMENT}
  mutation ShiftApplicationRateBatchUndo($idArray: [ID!]!) {
    shift_application_rate_batch_undo(input: { ids: $idArray }) {
      successes {
        id
        shift_application {
          ...shiftApplicationFragment
        }
      }
      failures {
        id
        message
      }
    }
  }
`;
