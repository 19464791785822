import { gql } from 'apollo-boost';

export const GET_PROFILE_OPTS_GQL = gql`
  query ProfileOptions {
    profile_options {
      id
      name
      trans_key
      sort_order
    }
  }
`;
