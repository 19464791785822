import { gql } from 'apollo-boost';

export const ADD_EXISTING_EMPLOYEE_GQL = gql`
  mutation EmploymentCreate($businessId: ID!, $userId: ID!, $role: String!, $permissions: [String!]) {
    employment_create(input: { businessId: $businessId, userId: $userId, role: $role, permissions: $permissions }) {
      employee {
        id
        name
        admin
        email
        phone_number
        notes
        roles
        first_image {
          id
          medium
        }
        employments {
          id
          role
          added_by {
            id
            name
          }
          business {
            id
          }
        }
      }
    }
  }
`;
