import { gql } from 'apollo-boost';

export const APPLICATION_COUNT = gql`
  query ShiftApplicationCounts($now: DateTime!, $businessId: ID!, $includes: [ShiftType!], $excludes: [ShiftType!]) {
    shift_application_counts(
      businessId: $businessId
      shiftEndedAfter: $now
      includedTypes: $includes
      excludedTypes: $excludes
    ) {
      pending_count
      invited_pending_count
      standby_count
      accepted_count
      rejected_count
      failed_to_attend_count
    }
  }
`;
