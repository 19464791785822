import { gql } from 'apollo-boost';

export const SHIFT_PROMO_CREATE = gql`
    mutation PromoCreate(
        $notifications: [ID!]!
        $recurring: Boolean,
        $shifts: [ShiftPromoCreateObject!]!
    ){
        shift_promo_create(input:{
            notifications: $notifications,
            recurring: $recurring,
            promoParams: $shifts
        }) {
            successes {
              shift {
                id
              }
            }
            failures {
              message
            }
        }
    }
`;
