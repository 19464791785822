import { gql } from 'apollo-boost';

export const INVITE_TO_BOOKING = gql`
  mutation ShiftInvite($bookingId: ID!, $dancerIds: [ID!]!) {
    shift_invite(input: { shiftId: $bookingId, dancerIds: $dancerIds }) {
      notifications {
        id
        shift {
          id
          invited_shift_applications_count
          shift_applications {
            dancer {
              id
              name
              first_image {
                id
                thumb
                medium
              }
            }
          }
        }
      }
    }
  }
`;
