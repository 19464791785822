import { gql } from 'apollo-boost';

import { MESSAGE_FRAGMENT } from '../fragments/message-fragment';

export const CREATE_MESSAGE = gql`
  ${MESSAGE_FRAGMENT}
  mutation MessageCreate($message: String!, $conversationId: ID!) {
    message_create(input: { conversationId: $conversationId, body: $message }) {
      message {
        ...messageFragment
      }
    }
  }
`;
