import { gql } from 'apollo-boost';

export const SEND_GROUP_ORDER_GQL = gql`
  mutation GroupOrder($groupIds: [ID!]!) {
    group_order(input: { ids: $groupIds }) {
      groups {
        id
      }
    }
  }
`;
