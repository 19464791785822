import { FC } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/global-hooks';

import { StagedCheckbox } from 'components/common/checkboxes/staged-checkbox';

import Loader from './Loader';
import Error from './Error';
import Empty from './Empty';
import Times from './Times';
import Info from './Info';
import Images from './Images';
import Ratings from '../Ratings';
import Actions from '../Actions';

import { BOOKING_STATUS, PERMISSIONS, UI_MODALS } from 'helpers/enums';
import { useHasPermission } from 'graphql/graph-hooks';

import { ListProps, RequestParams, ShiftApplication } from '../types';

const List: FC<ListProps> = ({
  data,
  isLoading,
  error,
  onActionSuccess,
  onUserRemoved,
  selectedIds,
  setSelectedIds,
}) => {
  const { t } = useTranslation();
  const { bookingId } = useParams<RequestParams>();
  const CAN_CHECK_IN = useHasPermission(PERMISSIONS.CHECK_IN_DANCERS);
  const { initModal } = useModal();

  const handleOnSelectToggle = (applicationId: string) => {
    setSelectedIds(
      selectedIds.includes(applicationId)
        ? selectedIds.filter((id) => applicationId !== id)
        : [...selectedIds, applicationId]
    );
  };

  const handleOpenDancerModal = (dancerId: string, appId: string) => {
    initModal(UI_MODALS.DANCER, {
      dancerId,
      appId,
      removeDancer: onUserRemoved,
      onActionSuccess,
    });
  };

  return (
    <div className='relative'>
      {isLoading && <Loader />}
      {error && <Error message={t('bookingCalendar.error_loading_booking')} />}
      {!error && !isLoading && !data.length && <Empty message={t('manageBookings.no_dancers')} />}

      {data.map((application: ShiftApplication) => {
        const { id, dancer, status, rating_sub_one, rating_sub_two, rating_sub_three } = application;
        const { city, state } = dancer.locations[0] || { city: null, state: null };
        const location = city && state ? `${city}, ${state}` : 'n/a';
        const isSelected = selectedIds.includes(id);
        return (
          <div key={id} className='flex justify-between mb-6'>
            <div className='flex justify-center items-center gap-8'>
              <StagedCheckbox
                stage={isSelected ? 'checked' : 'unchecked'}
                handleChange={handleOnSelectToggle.bind(this, id)}
              />
              <div className='flex flex-col w-72'>
                <p
                  className='text-base text-white font-bold leading-none cursor-pointer hover:text-business-purple'
                  onClick={handleOpenDancerModal.bind(this, dancer.id, id)}
                >
                  {dancer.name}
                </p>
                <p className='text-sm text-gray-400 leading-none'>{location}</p>
                <Times {...application} />
              </div>
              <Images
                dancer={dancer}
                status={status}
                isRated={rating_sub_one + rating_sub_two + rating_sub_three > 0}
                applicationId={id}
                images={dancer.images}
                onActionSuccess={onActionSuccess}
                onUserRemoved={onUserRemoved}
              />
            </div>

            <div className='flex gap-8 items-center justify-center'>
              {!bookingId && (
                <Info
                  shift={application.shift}
                  acceptedBy={application.accepted_by}
                  status={application.status}
                  isInvite={application.is_invite}
                />
              )}
              {bookingId && status === BOOKING_STATUS.CHECKED_OUT && (
                <Ratings
                  id={id}
                  canChange={CAN_CHECK_IN}
                  ratings={[rating_sub_one, rating_sub_two, rating_sub_three]}
                />
              )}
              <div className='grid grid-cols-2 gap-2 w-64'>
                <Actions
                  bookingId={bookingId}
                  applications={[application]}
                  onActionSuccess={onActionSuccess}
                  disabled={isSelected}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default List;
