import { gql } from 'apollo-boost';

export const BUSINESS_APP_STATE_GQL = gql`
  query ClientVersionAndBusinessAppState($businessId: ID!, $isAdmin: Boolean!, $client: String!) {
    client_version(clientIdentifier: $client) {
      client_version
    }
    business_app_state(businessId: $businessId) {
      admin_unread_message_count @include(if: $isAdmin)
      pending_verification_count @include(if: $isAdmin)
      pending_photo_verification_count @include(if: $isAdmin)
      unread_message_count
      pending_application_count
      pending_audition_application_count
      pending_invite_requests_count @include(if: $isAdmin)
      pending_dob_change_request_count @include(if: $isAdmin)
    }
  }
`;
