import { gql } from 'apollo-boost';

// Figure out how to add fragment above to this query
export const GET_BLAST_GQL = gql`
  query BlastAnnouncements {
    blast_announcements {
      id
      blast_type
      title
      message
      created_at
      user {
        id
        name
        first_image {
          id
          thumb
        }
      }
    }
  }
`;
