import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { gql } from 'apollo-boost';

export const GROUP_UPDATE_GQL = gql`
  ${DANCER_GRID_FRAGMENT}
  mutation GroupUpdate(
    $groupId: ID!
    $name: String
    $toAdd: [ID!]
    $toRemove: [ID!]
    $isAdmin: Boolean!
    $hasLocation: Boolean
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    group_update(input: { id: $groupId, dancerIdsToAdd: $toAdd, dancerIdsToRemove: $toRemove, name: $name }) {
      group {
        id
        name
        number_of_dancers
        dancers {
          ...DancerGridItem
        }
        business {
          id
          timezone
          longitude
          latitude
        }
      }
    }
  }
`;
