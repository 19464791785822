import { gql } from 'apollo-boost';
import { BUSINESS_FRAGMENT } from '../fragments/business-fragment';

export const ALL_BUSINESS_GQL = gql`
  ${BUSINESS_FRAGMENT}
  query Businesses {
    businesses {
      ...business
    }
  }
`;
