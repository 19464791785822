import { gql } from 'apollo-boost';

export const GET_SHIFT_BY_ID = gql`
  query Shift($id: ID!) {
    shift(id: $id) {
      id
      start_time
      end_time
      recurring
      slots
      shift_type
      title
      description
      hardcapped
      recurring_shift {
        id
        sibling_id
        siblings {
          id
          start_time
        }
      }
      pending_shift_applications_count
      invited_shift_applications_count
      accepted_shift_applications_count
      rejected_shift_applications_count
      checked_in_shift_applications_count
      checked_out_shift_applications_count
      has_applications
      auto_checkout_interval
      first_image {
        id
        thumb
        medium
      }
      pre_approved_groups {
        id
        name
      }
    }
  }
`;
