import moment from 'moment';

import { RouteConstants } from 'components/routes/route-constants';
import { BOOKING_STATUS } from 'helpers/enums';
import * as TimeHelpers from 'helpers/times';
import { CountsVars, GetCountsVarInput, GetRequestsVariablesInput, ShiftApplication } from './types';

const now = new Date().toISOString();

export const getCountsVariables = ({ businessId, type }: GetCountsVarInput) => {
  const countsVariables: CountsVars = {
    businessId,
    now,
  };

  if (type === 'auditions') {
    countsVariables.includes = ['AUDITION'];
  } else {
    countsVariables.excludes = ['AUDITION'];
  }

  return countsVariables;
};

export const getRequestsVariables = ({
  bookingId,
  isAdmin,
  cursor = '',
  businessId,
  status,
  search,
  invited,
}: GetRequestsVariablesInput) => {
  return bookingId
    ? {
        bookingId,
        isAdmin,
        hasLocation: true,
        blockedByBusinessId: businessId,
        inGroupBusinessId: businessId,
      }
    : {
        first: 20,
        isAdmin,
        now,
        cursor,
        businessId,
        blockedByBusinessId: businessId,
        inGroupBusinessId: businessId,
        status,
        search,
        hasLocation: true,
        invited,
      };
};

export const initialPageInfo = { endCursor: '', hasNextPage: false };

export const initialCounts: { [key: string]: number } = {
  accepted_count: 0,
  checked_in_count: 0,
  checked_out_count: 0,
  failed_to_attend_count: 0,
  pending_count: 0,
  invited_pending_count: 0,
  rejected_count: 0,
  standby_count: 0,
};

export const Time = (time: Date, tz: string, format = 'h:mm a') => {
  return TimeHelpers.getFormattedTimeZone(time, tz, format);
};

export const DateTime = (time: Date, tz: string, format = 'DD/MM/YY h:mm a') => {
  return TimeHelpers.getFormattedTimeZone(time, tz, format);
};

export const isInEditableRange = (start: Date, end: Date) => {
  const NOW = moment();
  const START = moment(start).startOf('day');
  const END = moment(end).add(2, 'days').endOf('day');
  return NOW.isBetween(START, END);
};

export const isInRejectRange = (end: Date) => {
  const END = moment(end).add(2, 'days').endOf('day');
  return moment().isSameOrBefore(END);
};

export const filterApplications = (
  bookingId: string | undefined,
  status: BOOKING_STATUS,
  nodes: ShiftApplication[],
  filter?: BOOKING_STATUS | 'ALL'
) => {
  if (bookingId && status === BOOKING_STATUS.ACCEPTED) {
    return nodes.filter((node) =>
      (filter === 'ALL'
        ? [
            BOOKING_STATUS.ACCEPTED,
            BOOKING_STATUS.CHECKED_IN,
            BOOKING_STATUS.CHECKED_OUT,
            BOOKING_STATUS.FAILED_TO_ATTEND,
          ]
        : [filter]
      ).includes(node.status)
    );
  } else {
    return nodes.filter(
      (item: ShiftApplication) =>
        (item.status === BOOKING_STATUS.PENDING && item.is_invite && status === BOOKING_STATUS.INVITED) ||
        (item.status === BOOKING_STATUS.PENDING && !item.is_invite && status === BOOKING_STATUS.PENDING) ||
        (item.status !== BOOKING_STATUS.PENDING && item.status === status)
    );
  }
};

export const getApplicationCountKey = (application: ShiftApplication) => {
  return application.status === BOOKING_STATUS.PENDING && application.is_invite
    ? 'invited_pending'
    : application.status.toLowerCase();
};

export const getFormattedApplicationCounts = (subtractInvites: boolean, counts: { [key: string]: number }) => {
  const {
    accepted_count = 0,
    checked_in_count = 0,
    checked_out_count = 0,
    failed_to_attend_count = 0,
    pending_count = 0,
    invited_pending_count = 0,
    rejected_count = 0,
    standby_count = 0,
  } = counts || initialCounts;

  return {
    accepted: accepted_count,
    checked_in: checked_in_count,
    checked_out: checked_out_count,
    failed_to_attend: failed_to_attend_count,
    pending: subtractInvites ? Math.max(0, pending_count - invited_pending_count) : pending_count,
    invited: invited_pending_count,
    rejected: rejected_count,
    standby: standby_count,
  };
};

export const getBookingDateString = ({
  start,
  end,
  timezone,
  language,
}: {
  start: Date;
  end: Date;
  timezone: string;
  language: string;
}) => {
  const START = TimeHelpers.createMoment(start, timezone).locale(language);
  const END = TimeHelpers.createMoment(end, timezone).locale(language);
  const START_MONTH = START.format('MMMM');
  const START_DAY = START.format('D');
  const END_MONTH = END.format('MMMM');
  const END_DAY = END.format('D');
  const TIME = `| ${START.format('h:mma')} - ${END.format('h:mma')}`;

  return START_MONTH === END_MONTH
    ? `${START_MONTH} ${START_DAY} ${START_DAY === END_DAY ? TIME : `- ${END_DAY} ${TIME}`}`
    : `${START_MONTH} ${START_DAY} - ${END_MONTH} ${END_DAY} ${TIME}`;
};

export const getBookingDateLink = ({ start, timezone }: { start: Date; timezone: string }) =>
  `${RouteConstants.calendar}?date=${TimeHelpers.getFormattedTimeZone(start, timezone, TimeHelpers.TimePatterns.date)}`;
