import { gql } from 'apollo-boost';

import { COURSE_FRAGMENT } from '../fragments/course-fragment';

export const COURSES_AND_CATEGORIES_GQL = gql`
  ${COURSE_FRAGMENT}
  query CoursesAndCategories {
    courses {
      ...courseKeys
    }
    course_categories {
      id
      name
    }
  }
`;
