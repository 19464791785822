import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { GROUP_NAME_LIST_FRAGMENT } from '../fragments/group-name-fragment';
import { gql } from 'apollo-boost';

export const MODAL_ADD_TO_GROUP_DATA = gql`
  ${DANCER_GRID_FRAGMENT}
  ${GROUP_NAME_LIST_FRAGMENT}
  query GroupsAndDancers(
    $businessId: ID!
    $dancerIds: [ID!]!
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    groups(businessId: $businessId) {
      ...GroupNameList
    }
    dancers(businessId: $businessId, dancerIds: $dancerIds) {
      nodes {
        ...DancerGridItem
      }
    }
  }
`;
