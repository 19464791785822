import { gql } from 'apollo-boost';

export const UPDATE_PRE_APPROVED_BOOKING = gql`
  mutation GroupPreapprovedShiftsUpdate(
    $groupId: ID!
    $nonRecurringToAdd: [ID!]
    $nonRecurringToRemove: [ID!]
    $recurringToAdd: [ID!]
    $recurringToRemove: [ID!]
  ) {
    group_preapproved_shifts_update(
      input: {
        groupId: $groupId
        nonRecurringShiftIdsToAdd: $nonRecurringToAdd
        nonRecurringShiftIdsToRemove: $nonRecurringToRemove
        recurringShiftIdsToAdd: $recurringToAdd
        recurringShiftIdsToRemove: $recurringToRemove
      }
    ) {
      clientMutationId
    }
  }
`;
