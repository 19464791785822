import { gql } from 'apollo-boost';

export const GET_INVITED_DANCER_FROM_CODE_GQL = gql`
  query RegistrationCode($codeId: ID!) {
    registration_code(id: $codeId) {
      id
      dancers {
        id
        name
        first_image {
          id
          thumb
          medium
        }
      }
    }
  }
`;
