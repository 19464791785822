import { gql } from 'apollo-boost';

export const GET_VIP_PROMOS = gql`
  query VipPromos {
    vip_sections {
      id
      name {
        en
        es
      }
      channel
      icon
      is_active
      sort_order
      created_at
      updated_at
      offers {
        id
        name {
          en
          es
        }
        created_at
        updated_at
        is_active
        sort_order
        section {
          id
          name {
            en
            es
          }
        }
        description {
          en
          es
        }
        image {
          id
          medium
          large
          data_content_type
          data_file_name
        }
        links {
          id
          name {
            en
            es
          }
          link_url
          link_type
          created_at
          updated_at
          sort_order
        }
      }
    }
  }
`;
