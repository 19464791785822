import { gql } from 'apollo-boost';

export const CREATE_LOCATION = gql`
  mutation LocationCreate($city: String!, $state: String!, $country: String, $dancerId: ID, $googlePlacesId: ID) {
    location_create(
      input: { city: $city, state: $state, country: $country, dancerId: $dancerId, googlePlacesId: $googlePlacesId }
    ) {
      location {
        id
        city
        state
        country
        google_places_id
      }
    }
  }
`;
