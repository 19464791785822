import { gql } from 'apollo-boost';

export const ADD_ORG_GQL = gql`
  mutation OrganizationCreate($name: String!) {
    organization_create(input: { name: $name }) {
      organization {
        id
        name
      }
    }
  }
`;
