import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import S from './courses-page.module.scss';
import { CoursesList } from './courses-list';
import { CoursesSort } from './courses-sort';
import { CoursesHeader } from './courses-header';
import { LoadingDiscoWithContainer } from '../../common/loading/loading-disco';
import { COURSES_AND_CATEGORIES_GQL } from '../../../graphql/queries/course-page-query';
import { logError } from '../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

const CoursesPage = () => {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState(null);
  const [filter, setFilter] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sortField, setSortField] = useState('title');
  const [isAsc, setIsAsc] = useState(true);

  const { data, loading, error } = useQuery(COURSES_AND_CATEGORIES_GQL, {
    onError: (err) => logError(err, 'COURSES_AND_CATEGORIES_GQL', CoursesPage.displayName),
    fetchPolicy: 'network-only',
  });

  if (loading) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;

  return (
    <div className={S.pageWrapper}>
      <CoursesHeader
        filter={filter}
        setFilter={setFilter}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <CoursesSort isAsc={isAsc} setIsAsc={setIsAsc} sortField={sortField} setSortField={setSortField} />

      <CoursesList
        sortField={sortField}
        isAsc={isAsc}
        filter={filter}
        courses={data.courses}
        selectedId={selectedId}
        selectedCategories={selectedCategories}
        handleSelection={setSelectedId}
      />
    </div>
  );
};

CoursesPage.displayName = 'CoursesPage';

export { CoursesPage };
