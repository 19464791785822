import { gql } from 'apollo-boost';

export const SUBMIT_RATING_GQL = gql`
  mutation ShiftApplicationRate($id: ID!, $rating1: Float!, $rating2: Float!, $rating3: Float!) {
    shift_application_rate(
      input: { id: $id, ratingSubOne: $rating1, ratingSubTwo: $rating2, ratingSubThree: $rating3 }
    ) {
      shift_application {
        id
        rating_sub_one
        rating_sub_two
        rating_sub_three
      }
    }
  }
`;
