import { gql } from 'apollo-boost';

export const COURSES_CATEGORY_GQL = gql`
  query CourseCategories {
    course_categories {
      id
      name
    }
  }
`;
