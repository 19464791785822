import { gql } from 'apollo-boost';

export const UNSUSPEND_DANCER = gql`
  mutation DancerUnsuspend($dancerId: ID!) {
    dancer_unsuspend(input: { dancerId: $dancerId }) {
      clientMutationId
      dancer {
        suspended
      }
    }
  }
`;
