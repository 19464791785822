import React, { ChangeEvent, FC } from 'react';
import * as DancerSearchCTX from '../../dancer-search-context';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './filter.module.scss';
import { InputFormSelect } from '../../../../../common/form/form-parts/input-form-select';
import * as DancerSearchCtx from '../../dancer-search-context';

interface Props {
  free_agent_search_enabled: boolean;
}

export const FilterDancerFreeAgent: FC<Props> = ({ free_agent_search_enabled }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    searchParams: { freeAgents },
  } = DancerSearchCTX.useDancerSearchCtx(false);

  const handleChange = (e:{target: {value:string}}) => dispatch(DancerSearchCtx.setDancerSearchField('freeAgents', e.target?.value));

  const inputOptions = free_agent_search_enabled ? [
    { value: 'true', label: t('common.free_agents') },
    { value: 'false', label: t('common.non_free_agents') },
    { value: '', label: t('common.all') },
  ] : [
    { value: 'false', label: t('common.non_free_agents') }
  ];

  return (
    <div className={classNames(S.leftWrapper, S.control, { [S.disabled]: !free_agent_search_enabled })}>
      <InputFormSelect
        id='sortField'
        name='sort'
        isDisabled={!free_agent_search_enabled}
        value={freeAgents === undefined ? '' : String(freeAgents)}
        onChange={handleChange}
        options={inputOptions}
      />
    </div>
  );
};
