import { gql } from 'apollo-boost';

export const SHIFT_EVENT_UPDATE = gql`
  mutation ShiftEventUpdate(
    $eventId: ID!
    $startTime: DateTime!
    $endTime: DateTime!
    $slots: Int!
    $title: String!
    $description: String!
    $preapprovedIds: [ID!]!
    $autoCheckoutInterval: Int
    $hardcapped: Boolean!
    $imageInput: ImageInputNullableObject
  ) {
    shift_event_update(
      input: {
        eventUpdateParams: {
          id: $eventId
          startTime: $startTime
          endTime: $endTime
          preapprovedGroupIds: $preapprovedIds
          slots: $slots
          title: $title
          description: $description
          autoCheckoutInterval: $autoCheckoutInterval
          hardcapped: $hardcapped
          imageInput: $imageInput
        }
      }
    ) {
      event {
        id
        start_time
        end_time
        title
        description
        slots
        business {
          id
        }
        preapproved_group_ids
        auto_checkout_interval
      }
    }
  }
`;
