import { gql } from 'apollo-boost';

import { COURSE_FRAGMENT } from '../fragments/course-fragment';

export const COURSE_UPDATE = gql`
  ${COURSE_FRAGMENT}
  mutation CourseUpdate(
    $title: String!
    $description: String!
    $videoUrl: String!
    $imageUrl: String!
    $categories: [ID!]
    $courseId: ID!
  ) {
    course_update(
      input: {
        updateParams: {
          id: $courseId
          title: $title
          description: $description
          youtubeUrl: $videoUrl
          imageUrl: $imageUrl
          courseCategoryIds: $categories
        }
      }
    ) {
      course {
        ...courseKeys
      }
    }
  }
`;
