import { gql } from 'apollo-boost';

// Mutation for approving a dancer
export const APPROVE_DANCER = gql`
  mutation DancerApprove($dancerId: ID!) {
    dancer_approve(input: { id: $dancerId }) {
      clientMutationId
    }
  }
`;
