import { gql } from 'apollo-boost';
import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';

export const GET_BOOKING_GQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query Shift(
    $bookingId: ID!
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    shift(id: $bookingId) {
      id
      shift_type
      slots
      start_time
      end_time
      title
      description
      hardcapped
      accepted_shift_applications_count
      checked_in_shift_applications_count
      checked_out_shift_applications_count
      business {
        id
        timezone
        allow_dancer_reviews
      }
      shift_applications {
        id
        status
        is_invite
        accepted_by {
          id
          name
        }
        checked_in_at
        checked_in_by {
          id
          name
        }
        checked_out_at
        checked_out_by {
          id
          name
        }
        marked_missed_at
        marked_missed_by {
          id
          name
        }
        rating_sub_one
        rating_sub_two
        rating_sub_three
        updated_at
        dancer {
          ...DancerGridItem
        }
      }
    }
  }
`;
