import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { gql } from 'apollo-boost';

export const GET_GROUP_GQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query Group($groupId: ID!, $isAdmin: Boolean!, $blockedByBusinessId: ID) {
    group(id: $groupId) {
      id
      name
      number_of_dancers
      default
      dancers {
        ...DancerGridItem
      }
      business {
        id
        timezone
        longitude
        latitude
      }
    }
  }
`;
