import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';

import S from './filter-dancer-styles.module.scss';
import * as DancerSearchCTX from '../../dancer-search-context';
import { GET_DANCER_SKILLS_GQL } from 'graphql/queries/dancer-skills';
import { CheckBoxDropDown } from 'components/common/form/form-parts/checkbox-dropdown';

const DancerSkillsFilter = () => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { skillsFilter },
  } = DancerSearchCTX.useDancerSearchCtx();

  const [untranslatedMap, setUntranslatedMap] = useState({});
  const [untranslatedList, setUntranslatedList] = useState([]);
  const [filterList, setFilterList] = useState([]);

  const { loading } = useQuery(GET_DANCER_SKILLS_GQL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ skills }) => {
      const { map, list } = skills.reduce(
        (acm, { id, display_name }) => ({
          list: [...acm.list, { label: display_name, value: id }],
          map: { ...acm.map, [id]: display_name },
        }),
        { list: [], map: {} }
      );

      setUntranslatedMap(map);
      setUntranslatedList(_sortBy(list, 'label'));
    },
  });

  useEffect(() => {
    const translatedList = untranslatedList.map(({ label, value }) => ({
      label: label[i18n.language],
      value,
    }));
    setFilterList(translatedList);
  }, [i18n.language, untranslatedList, untranslatedMap]);

  return (
    !loading && (
      <div className={S.dancerStyles}>
        <CheckBoxDropDown
          placeholder={t('common.skills')}
          name='skillsFilter'
          selected={skillsFilter}
          list={filterList}
          handleChange={(val) => dispatch(DancerSearchCTX.setDancerSearchField('skillsFilter', val))}
        />
      </div>
    )
  );
};

DancerSkillsFilter.displayName = 'DancerSkillsFilter';

export { DancerSkillsFilter };
