import { gql } from 'apollo-boost';

export const GET_ORGANIZATIONS_BY_ID_GQL = gql`
  query Organization($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      name
    }
  }
`;
