import { gql } from 'apollo-boost';

export const GET_DANCER_SKILLS_GQL = gql`
  query Skills {
    skills {
      id
      name
      display_name
      sort_order
    }
  }
`;
