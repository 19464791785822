import { gql } from 'apollo-boost';

import { CONVERSATION_FRAGMENT } from '../fragments/conversation-fragment';

export const CREATE_CONVERSATION = gql`
  ${CONVERSATION_FRAGMENT}
  mutation ConversationCreateOrAdd(
    $businessId: ID!
    $dancerId: ID!
    $isAdmin: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    conversation_create_or_add(input: { businessId: $businessId, dancerId: $dancerId }) {
      conversation {
        ...conversationFragment
      }
    }
  }
`;
