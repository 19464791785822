import { gql } from 'apollo-boost';

export const GET_LOCATION = gql`
  query Location($id: ID, $googlePlacesId: String) {
    location(id: $id, googlePlacesId: $googlePlacesId) {
      id
      city
      state
      country
      google_places_id
    }
  }
`;
