import { gql } from 'apollo-boost';

export const COURSE_CATEGORY_CREATE = gql`
  mutation CourseCategoryAdd($name: String!) {
    course_category_add(input: { name: $name }) {
      course_category {
        id
        name
      }
    }
  }
`;
