import { gql } from 'apollo-boost';

import { CONVERSATION_FRAGMENT } from '../fragments/conversation-fragment';

export const GET_CHAT_LIST = gql`
  ${CONVERSATION_FRAGMENT}
  query Conversations($businessId: ID, $userId: ID!, $isAdmin: Boolean!, $blockedByBusinessId: ID) {
    conversations(businessId: $businessId, userId: $userId) {
      ...conversationFragment
    }
  }
`;
