import { gql } from 'apollo-boost';

import { COURSE_FRAGMENT } from '../fragments/course-fragment';

export const COURSES_LIST_GQL = gql`
  ${COURSE_FRAGMENT}
  query Courses {
    courses {
      ...courseKeys
    }
  }
`;
