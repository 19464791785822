import { gql } from 'apollo-boost';

export const PROFILE_PICTURE_REJECT = gql`
  mutation ProfilePictureReject(
    $id: ID!
    $rejectionText: I18nTextInputObject!
    $rejectionReason: ImageRejectionReasonEnum!
  ) {
    profile_picture_reject(input: { id: $id, rejectionText: $rejectionText, rejectionReason: $rejectionReason }) {
      clientMutationId
      image {
        id
        is_verified
        rejection_text
      }
    }
  }
`;

export const PROFILE_PICTURE_APPROVE = gql`
  mutation ProfilePictureApprove($id: ID!) {
    profile_picture_approve(input: { id: $id }) {
      image {
        id
        is_verified
      }
    }
  }
`;
