import { gql } from 'apollo-boost';

import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';

export const GET_DANCER_GQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query Dancer(
    $dancerId: ID!
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    dancer(id: $dancerId) {
      ...DancerGridItem
      bio
      profile_options {
        id
        name
        trans_key
      }
      images {
        id
        thumb
        medium
        large
        is_verified
        rejection_text
      }
      licenses {
        id
        license_number
        name
        city
        county
        state
        issue_date
        expiration_date
      }
      registration_code {
        code
      }
    }
  }
`;

export const GET_APPLICATION_GQL = gql`
  query ShiftAplication($id: ID!) {
    shift_application(id: $id) {
      id
      status
      is_invite
      rating_sub_one
      rating_sub_two
      rating_sub_three
      shift {
        id
        shift_type
        start_time
        end_time
        slots
        hardcapped
        accepted_shift_applications_count
        checked_out_shift_applications_count
        checked_in_shift_applications_count
      }
    }
  }
`;
