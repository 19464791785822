import { APIConstants } from 'helpers/constants';
import Axios from 'axios';

export const checkText = (textBlock, listType) => {
  const query = `query Suggestions($textBlock: String!, $listType: SuggestionsListTypeEnum!){
    suggestions(textBlock: $textBlock, listType: $listType){
      word
      list_type    
      alternatives
    }
  }`;
  return Axios.post(
    `${APIConstants.graphq_ql}`,
    { query, variables: { textBlock, listType } },
    { headers: { Authorization: `Token: ${localStorage.getItem('token')}` } }
  );
};
