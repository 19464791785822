import { gql } from 'apollo-boost';

export const DELETE_INVITE_CODE = gql`
  mutation RegistrationCodeDelete($id: ID!) {
    registration_code_delete(input: { id: $id }) {
      clientMutationId
      message
    }
  }
`;
