import { gql } from 'apollo-boost';

export const SUSPEND_DANCER = gql`
  mutation DancerSuspend($id: ID!, $reason: String!) {
    dancer_suspend(input: { dancerId: $id, reason: $reason }) {
      dancer {
        suspended
      }
      clientMutationId
    }
  }
`;
