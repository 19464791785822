import { gql } from 'apollo-boost';

export const GET_EMPLOYEE_BY_ORG_GQL = gql`
  query EmmployeeByOrganization($orgId: ID!) {
    organization(id: $orgId) {
      id
      name
      businesses {
        id
        name
        employments {
          id
          employee {
            id
            name
            roles
            first_image {
              id
              thumb
            }
          }
        }
      }
    }
  }
`;
