import { gql } from 'apollo-boost';

export const CREATE_GROUP_GQL = gql`
  mutation GroupCreate($businessId: ID!, $name: String!, $dancerIds: [ID!]) {
    group_create(input: { businessId: $businessId, name: $name, dancerIds: $dancerIds }) {
      group {
        id
        name
        number_of_dancers
        order
        dancers {
          id
        }
        business {
          id
          timezone
          longitude
          latitude
        }
      }
    }
  }
`;
