import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/global-hooks';

import DancerImgWithStatus from './DancerImgWithStatus';

import { UI_MODALS } from 'helpers/enums';
import { ImagesProps } from '../types';

const Images: FC<ImagesProps> = ({
  dancer,
  status,
  isRated,
  images,
  applicationId,
  onActionSuccess,
  onUserRemoved,
}) => {
  const { t } = useTranslation();
  const { initModal } = useModal();
  const sliced = images.slice(0, 2);

  const handleOpenDancerModal = () => {
    initModal(UI_MODALS.DANCER, {
      dancerId: dancer.id,
      appId: applicationId,
      onActionSuccess,
      removeDancer: onUserRemoved,
    });
  };

  return (
    <div className='flex'>
      {sliced.map((image) => {
        return (
          <DancerImgWithStatus
            key={image.id}
            status={status}
            isRated={isRated}
            name={dancer.name}
            inGroup={dancer.in_group}
            src={image.thumb}
            handleSelection={handleOpenDancerModal}
          />
        );
      })}

      {sliced.length < images.length && (
        <div className='flex flex-col items-center justify-center '>
          <div className='text-3xl text-center font-bold mb-2'>+{images.length - sliced.length}</div>
          <button
            onClick={handleOpenDancerModal}
            className='border border-solid border-business-purple rounded text-business-purple px-4 py-1 hover:bg-business-purple hover:text-white'
          >
            {t('common.showAll')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Images;
