import { gql } from 'apollo-boost';

export const GET_ORGANIZATIONS_GQL = gql`
  query Organizations {
    organizations {
      id
      name
    }
  }
`;
