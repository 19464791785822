import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';

import S from './filter-dancer-styles.module.scss';
import * as DancerSearchCTX from '../../dancer-search-context';
import { GET_PROFILE_OPTS_GQL } from '../../../../../../graphql/queries/profile-options';
import { CheckBoxDropDown } from 'components/common/form/form-parts/checkbox-dropdown';

const DancerStylesFilter = () => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { styleFilter },
  } = DancerSearchCTX.useDancerSearchCtx();

  const [untranslatedMap, setUntranslatedMap] = useState({});
  const [untranslatedList, setUntranslatedList] = useState([]);

  const [filterList, setFilterList] = useState([]);
  const { loading } = useQuery(GET_PROFILE_OPTS_GQL, {
    onCompleted: ({ profile_options }) => {
      const { map, list } = profile_options.reduce(
        (acm, { id, trans_key }) => ({
          list: [...acm.list, { label: trans_key, value: id }],
          map: { ...acm.map, [id]: trans_key },
        }),
        { list: [], map: {} }
      );

      setUntranslatedMap(map);
      setUntranslatedList(_sortBy(list, 'sort_order'));
    },
  });

  useEffect(() => {
    const translatedList = untranslatedList.map(({ label, value }) => ({
      label: t(`profileOptions.${label}`),
      value,
    }));
    setFilterList(translatedList);
  }, [untranslatedMap, untranslatedList, i18n.language]);

  return (
    !loading && (
      <div className={S.dancerStyles}>
        <CheckBoxDropDown
          placeholder={t('common.preferences')}
          name='styleFilter'
          selected={styleFilter}
          list={filterList}
          handleChange={(val) => dispatch(DancerSearchCTX.setDancerSearchField('styleFilter', val))}
        />
      </div>
    )
  );
};

DancerStylesFilter.displayName = 'DancerStylesFilter';

export { DancerStylesFilter };
