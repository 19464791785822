import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { DANCER_SORTS } from './dancer-search-types';
import { useBusiness, useUser } from '../../../../graphql/graph-hooks';
import { DANCER_PAGES, ORDER } from '../../../../helpers/enums';

const SET_DANCER_SEARCH_PAGE_PAGINATION = 'SET_DANCER_SEARCH_PAGE_PAGINATION';
const SET_DANCER_SEARCH_PAGE_FIELDS = 'SET_DANCER_SEARCH_PAGE_FIELDS';
const SET_DANCER_SEARCH_PAGE_KEY = 'SET_DANCER_SEARCH_PAGE_KEY';
const SET_DANCER_SORT_FIELDS = 'SET_DANCER_SORT_FIELDS';

const PAGE_BOOKING_REQUEST_INIT_STATE = {
  total: 0,
  hasBlocked: false,
  nameFilter: '',
  businessIdFilter: '',
  deletedFilter: false,
  freeAgentFilter: null,
  freeAgents: '',
  styleFilter: [],
  skillsFilter: [],
  locationInput: '',
  is21: true,
  location: undefined,
  pagination: { endCursor: '', hasNextPage: false },
  sort: { order: ORDER.ASC, orderBy: DANCER_SORTS.NAME },
  selected: [],
};

export const setDancerSearchField = (field, payload) => ({
  type: SET_DANCER_SEARCH_PAGE_KEY,
  field,
  payload,
});

export const setDancerSearchFields = (payload) => ({
  type: SET_DANCER_SEARCH_PAGE_FIELDS,
  payload,
});

export const setDancerSearchPagination = (payload) => ({
  type: SET_DANCER_SEARCH_PAGE_PAGINATION,
  payload,
});

export const setDancerSortFields = (payload) => ({
  type: SET_DANCER_SORT_FIELDS,
  payload,
});

function pageDancerSearchReducer(state, { type, field, payload }) {
  switch (type) {
    case SET_DANCER_SEARCH_PAGE_KEY:
      return { ...state, [field]: payload };

    case SET_DANCER_SEARCH_PAGE_FIELDS:
      return { ...state, ...payload };

    case SET_DANCER_SORT_FIELDS:
      return { ...state, sort: { ...state.sort, ...payload } };

    case SET_DANCER_SEARCH_PAGE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...payload,
        },
      };

    default:
      throw new Error(`Unsupported action type: ${type}`);
  }
}

const PAGE_DANCER_SEARCH_CTX = React.createContext();

export function DancerSearchProvider({ page, children }) {
  const { longitude, latitude, city, state: bizState } = useBusiness();
  const [state, dispatch] = React.useReducer(pageDancerSearchReducer, {
    ...PAGE_BOOKING_REQUEST_INIT_STATE,
    ...{
      isSuspended: page === DANCER_PAGES.SUSPENDED,
      isBlocked: page === DANCER_PAGES.BLOCKED,
      hasLocation: page !== DANCER_PAGES.SUSPENDED,
      locationInput: page === DANCER_PAGES.SEARCH ? `${city}, ${bizState}` : '',
      location: { lon: longitude, lat: latitude },
    },
  });

  const value = React.useMemo(() => [state, dispatch], [state]);

  useEffect(() => {
    dispatch(
      setDancerSearchFields({
        ...PAGE_BOOKING_REQUEST_INIT_STATE,
        isSuspended: page === DANCER_PAGES.SUSPENDED,
        isBlocked: page === DANCER_PAGES.BLOCKED,
        hasLocation: page !== DANCER_PAGES.SUSPENDED,
        locationInput: page === DANCER_PAGES.SEARCH ? `${city}, ${bizState}` : '',
        location: { lon: longitude, lat: latitude },
      })
    );
  }, [page, longitude, latitude, city, bizState]);

  return <PAGE_DANCER_SEARCH_CTX.Provider value={value} {...{ page, children }} />;
}

DancerSearchProvider.propTypes = {
  page: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export function useDancerSearchCtx(isAdminSearch) {
  const { admin } = useUser();
  const { id, free_agent_search_enabled } = useBusiness();
  const context = React.useContext(PAGE_DANCER_SEARCH_CTX);

  if (!context) {
    throw new Error(`${useDancerSearchCtx.name} must be used within a ${pageDancerSearchReducer.name}`);
  }

  const [state, dispatch] = context;
  const STYLES = state.styleFilter?.filter((id) => +id > 0);
  const SKILLS = state.skillsFilter?.filter((id) => +id > 0);
  const searchParams = isAdminSearch
    ? {
        businessId: state.businessIdFilter,
        deleted: state.deletedFilter,
        freeAgent: state.freeAgentFilter,
        search: state.nameFilter,
        isAdmin: admin,
        hasLocation: state.hasLocation,
        blockedByBusinessId: id,
        inGroupBusinessId: id,
        pagination: state.pagination,
        first: 30,
      }
    : {
        isAdmin: admin,
        businessId: id || 0,
        blockedByBusinessId: id,
        inGroupBusinessId: id,
        isSuspended: state.isSuspended,
        isBlocked: state.hasBlocked === '' ? undefined : state.hasBlocked === 'true',
        hasLocation: state.hasLocation,
        order: state.sort.order,
        orderBy: state.sort.orderBy,
        overTwentyOne: false, // can't be toggled atm but we'll leave it here to avoid unexpected response
        options: !state.isSuspended && STYLES.length > 0 ? STYLES : undefined,
        skills: !state.isSuspended && SKILLS.length > 0 ? SKILLS : undefined,
        freeAgents: free_agent_search_enabled
          ? state.freeAgents === ''
            ? undefined
            : state.freeAgents === 'true'
          : false,
        nameLike: !state.isSuspended ? state.nameFilter : '',
        location: !state.isSuspended ? state.location : undefined,
        after: '',
      };

  return {
    state,
    dispatch,
    searchParams,
  };
}
