import { gql } from 'apollo-boost';

import { MESSAGE_FRAGMENT } from '../fragments/message-fragment';

export const MESSAGE_MARK_AS_READ = gql`
  ${MESSAGE_FRAGMENT}
  mutation MessageMarkAsRead($messageId: ID!) {
    message_mark_as_read(input: { messageId: $messageId }) {
      message {
        ...messageFragment
      }
    }
  }
`;
