import { SHIFT_APPLICATION_FRAGMENT } from '../fragments/shift-application-fragment';
import { gql } from 'apollo-boost';

export const BOOKING_CANCEL_CHECK_IN_GQL = gql`
  ${SHIFT_APPLICATION_FRAGMENT}
  mutation ShiftApplicationCheckinCancel($idArray: [ID!]!) {
    shift_application_checkin_cancel(input: { ids: $idArray }) {
      failures {
        id
        message
      }
      successes {
        message
        shift_application {
          ...shiftApplicationFragment
        }
      }
    }
  }
`;
