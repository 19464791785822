import { gql } from 'apollo-boost';

export const CALENDAR_PAGE_BOOKING_LIST_GQL = gql`
  query Shifts($businessID: ID!, $endsAfter: DateTime!, $startsBefore: DateTime!) {
    shifts(endsAfter: $endsAfter, startsBefore: $startsBefore, businessId: $businessID) {
      nodes {
        id
        start_time
        end_time
        shift_type
        invited_shift_applications_count
        pending_shift_applications_count
        accepted_shift_applications_count
        rejected_shift_applications_count
        checked_in_shift_applications_count
        checked_out_shift_applications_count
        recurring
        has_applications
        slots
      }
    }
  }
`;
